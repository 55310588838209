@import (less) "../Theme/constants.css";

.container {
  display: flex;
}

@import (less) "../Theme/constants.css";

.thinWrapper {
  margin: 0 39*@unit;
}

.introIcon {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 48*@unit;
  height: 48*@unit;
}

.introBullet {
  padding-left: 48*@unit;
  margin-bottom: 48*@unit;
  position: relative;
  text-align: left;

  /* line connecting all three icons */
  &::before {
    content: '';
    display: block;
    background-color: @color-body-text;
    width: 2*@unit;
    height: 150%;
    position: absolute;
    top: 24*@unit;
    left: 23*@unit;
  }

  &:last-child::before {
    display: none;
  }
}

.introBullets {
  padding-left: 0;
  margin-top: 41*@unit;
  display: table;
  list-style: none;
}

.introText {
  padding-left: 17*@unit;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  height: 48*@unit;
  display: table-cell;
  vertical-align: middle;
}

.selfieIcon {
  background-image: url('assets/selfie-picto.svg');
  background-size: contain;
}

.glassesIcon {
  background-image: url('assets/glasses-picto.svg');
  background-size: contain;
}

.introCopy {
  margin-bottom: auto;
  overflow: auto;
}

