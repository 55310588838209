@import (less) "../Theme/constants.css";

.navigation {
  @navigation-all-padding: @navigation-padding-top @navigation-padding-sides 0;
  height: @navigation-height;
  padding: @navigation-all-padding;
  text-align: left;
  box-sizing: content-box;
  @media (--small-viewport) {
    @navigation-all-padding: @navigation-padding-top-sm-screen @navigation-padding-sides-sm-screen 0;
    height: @navigation-height-sm-screen;
    padding: @navigation-all-padding;
  }
}

.fullScreenNav {
  background: linear-gradient(0deg, @color-navbar-gradient-one 0%, @color-navbar-gradient-two 100%);
  z-index: 3;

  .backHoverDesktop {
    &:hover {
      .iconBack {
        background-image: url('assets/back-black.svg');
        background-color: @color-icon-button-hover;
      }
    }
  }

  .back {
    color: @color-white;

    &:active {
      background-image: url('assets/back-black.svg');

      .iconBack {
        background-color: @color-icon-button-active;
      }
    }
  }

  .iconBack {
    background-image: url('assets/back-white.svg');
  }
}

.backHoverDesktop {
  &:hover, &:focus {
    .label {
      visibility: visible;
    }
  }

  &:hover {
    .iconBack {
      background-color: @color-icon-button-hover;
    }
  }

  &:active {
    .iconBack {
      background-color: @color-icon-button-active;
    }
  }
}

.disabled {
  display: none;
}

.back {
  height: 32*@unit;
  color: @color-body-text;
  padding: 0;
  font-size: inherit;
  line-height: 1;
  border: none;
  background-color: @color-icon-button;
  cursor: pointer;

  @media (--small-viewport) {
    width: auto;
  }
}

.iconBack {
  height: 32*@unit;
  width: 32*@unit;
  background-image: url('assets/back-black.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: @color-transparent;
  border-radius: 16*@unit;
  display: inline-block;
  vertical-align: middle;
}

.label {
  visibility: hidden;
  margin: 0 7*@unit;
  font-size: @font-size-small;

  @media (--small-viewport) {
    display: none;
  }
}
