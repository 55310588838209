@import (less) "../../Theme/constants.css";

.instructions {
  font-size: @font-size-small;

  text-align: left;
  border-radius: 3*@unit-small;
  border: 1px solid @color-background;
  margin: 30*@unit-small 0 24*@unit-small;
  position: relative;
  padding: 24*@unit-small 24*@unit-small 12*@unit-small;
  @media (--small-viewport) and (--short-viewport) {
    font-size: @font-size-x-small;
  }
  @media (--small-viewport) {
    margin: 24*@unit-small 0 12*@unit-small;
  }
}

.instructionsTitle {
  font-weight: 600;
  margin: 0 0 8*@unit-small;
}

.recovery {
  background: @color-secondary-button-text;
  border-radius: 14*@unit-small;
  color: @color-white;
  font-weight: 600;
  left: 20*@unit-small;
  line-height: 24*@unit-small;
  padding: 0 15*@unit-small;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}

.steps {
  padding: 0;
  margin: 0;
}

.step {
  margin: 0 15*@unit-small 10*@unit-small;
}

.button {
  margin-left: auto;
  margin-right: auto;
}
