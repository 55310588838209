@import (less) "../Theme/constants.css";

.loader {
  width: 48*@unit;
  height: 48*@unit;
  outline: none;
  &:extend(.absolute-center);
}

@keyframes ball-scale-ripple-multiple {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0.0;
  }
}

.inner {
  position: relative;
  transform: translateX(25*@unit);

  & > div:nth-child(0) {
    animation-delay: -0.8s;
  }

  & > div:nth-child(1) {
    animation-delay: -0.6s;
  }

  & > div:nth-child(2) {
    animation-delay: -0.4s;
  }

  & > div:nth-child(3) {
    animation-delay: -0.2s;
  }

  & > div {
    animation-fill-mode: both;
    position: absolute;
    top: -2*@unit;
    left: -26*@unit;
    width: 50*@unit;
    height: 50*@unit;
    border-radius: 100%;
    border: 2*@unit solid #ccc;
    animation: ball-scale-ripple-multiple 1.25s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  }
}
