@import (less) "../Theme/constants.css";
@import (less) "../Theme/style.css";

.camera {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  overflow: hidden;
}

.container:before {
  content: "";
  display: block;
  padding-top: 70%;
}

.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 0;
}

.video {
  display: block;
  height: 100%;
  z-index: 0;
  /* Use `object-fit: cover` on every browser but Edge */
  @supports not (-ms-ime-align:auto) {
    width:100%;
    top: 0;
    left: 0;
    margin: auto;
    object-fit: cover;
  }
}

.actions {
  position: absolute;
  z-index: 1000;
  bottom: 48*@unit;
  left: 16*@unit;
  right: 16*@unit;

  &.disabled {
    z-index: 0;
  }
}

.btn {
  cursor: pointer;
  outline-offset: 6*@unit;
  background-color: @color-camera-button;
  font-size: inherit;
  border-radius: 50%;
  border: 3*@unit solid @color-black;
  box-shadow: 0 0 0 4*@unit @color-white;
  height: 56*@unit;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: 56*@unit;

  &:hover {
    background-color: @color-camera-button-hover;
  }

  &:active {
    background-color: @color-camera-button-active;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: @color-camera-button-disabled;
  }
}


.webcamContainer {
  position: absolute;
  top: 0;
  height: 100%;
  /* Only apply styles on Edge where there is no `object-fit:cover` support for video */
  @supports (-ms-ime-align:auto) {
    left: 50%;
    margin: auto;
    transform: translate(-50%, 0%);
  }
}
