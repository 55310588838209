@import (less) "../../Theme/constants.css";

.icon {
  height: 144*@unit;
  background-size: contain;
  background-image: url('assets/return-to-computer-large.svg');
  margin-top: 50*@unit;
  margin-bottom: 50*@unit;
}

.text {
  font-size: @font-size-small;
  line-height: 1.43;
}
