@import (less) "../Theme/constants.css";

.uploadFallback {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 16*@unit;
  padding-bottom: 4*@unit;

  & button{
    /* ref: http://stackoverflow.com/a/12642009/689223 */
    /* Remove all decorations to look like normal text */
    background: none;
    border: none;
    display: inline;
    font: inherit;
    margin: 0;
    padding: 0;
    /* Additional styles to look like a link */
    cursor: pointer;

    /*Custom style*/
    color: @color-white;
    &:hover{
      text-decoration: underline;
    }
  }
}

.errorContainer {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}

.errorOverlay() {
  &::before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

.errorContainerType {
  .errorOverlay();
}

.errorHasBackdrop {
  .errorOverlay();
  bottom: 0;
  &::before {
    background-color: @color-camera-error-overlay;
    opacity: .57;
  }
}

.errorMessage {
  top: 48*@unit;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 2;
}
