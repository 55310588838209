@import (less) "../Theme/constants.css";

.privacy {
  width: 100%;
}

.content {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  color: @color-small-print;
}

.actions {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 24*@unit;
  @media (--small-viewport) {
    margin-bottom: 0;
  }
}

.list {
  line-height: 1.5;
  text-align: left;
  padding-left: 64*@unit;
  position: relative;
  margin-bottom: 0;
  @media (--small-viewport) {
    padding-left: 32*@unit;
  }
}

.item {
   list-style: none;
   margin-bottom: 16*@unit;
}

.item:before{
   content: '';
   display: inline-block;
   height: 16*@unit;
   width: 32*@unit;
   background-image: url('assets/tick-grey.svg');
   background-repeat: no-repeat;
   background-size: contain;
   position: relative;
   margin-left: -32*@unit;
}

.smallPrint {
  text-align: left;
  font-size: @font-size-x-small;
  font-weight: 500;
  line-height: 1.64;
  margin-bottom: 24*@unit-x-small;
}

.smallPrint a {
  color: @color-small-print;
  margin-top: 10*@unit-x-small;
  margin-bottom: 10*@unit-x-small;
}

.decline {
  width: 136*@unit;
  border: 1px solid @color-button-border;
  border-radius: 28*@unit;
  background-color: @color-transparent;
  color: @color-secondary-button-text;
  line-height: 1;
  @media (--small-viewport) {
    width: 112*@unit;
    padding: 0 16*@unit;
  }
}

.primary {
  @media (--small-viewport) {
    width: 152*@unit;
  }
}
