@import (less) "../Theme/constants.css";

@lg_btn_width_lg_screen: 272*@unit;
@lg_btn_width_sm_screen: 100%;

@sm_btn_width_lg_screen: 200*@unit;
@sm_btn_width_sm_screen: 160*@unit;


.button {
  height: 56*@unit;
  line-height: 56*@unit;
  font: inherit;
  font-weight: 500;

  text-decoration: none;
  text-align: center;
  cursor: pointer;

  display: inline-block;
  border: none;
  border-radius: 4*@unit;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
    background-color: @color-primary-button-disabled;
    color: darken(@color-primary-button-disabled, 20%);
  }
}

.button-text {
  font-size: @font-size-small;
}

.button-centered {
  margin: auto;
}

.button-primary {
  /*text*/
  color: @color-white;

  /*layout*/
  display: block;
  max-width: 100%;

  border-radius: 4*@unit;
  border: none;

  background-color: @color-primary-button;

  &.hoverDesktop:not(:disabled) {
    &:hover {
      background-color: @color-primary-button-hover;
    }
  }

  &:not(:disabled) {
    &:active,
    &.hoverDesktop:active {
      background-color: @color-primary-button-active;
    }
  }
}

.button-lg {
  width: @lg_btn_width_lg_screen;
  @media (--small-viewport) {
    width: @lg_btn_width_sm_screen;
  }
}

.button-sm {
  width: @sm_btn_width_lg_screen;
  @media (--small-viewport) {
    width: @sm_btn_width_sm_screen;
  }
}

.button-secondary {
  border: 1px solid @color-button-border;
  border-radius: 4*@unit;
  background-color: @color-transparent;
  color: @color-secondary-button-text;
  line-height: 16*@unit;

  &.hoverDesktop {
    &:hover {
      background-color: @color-secondary-button-hover;
    }
  }

  &:active,
  &.hoverDesktop:active {
    background-color: @color-secondary-button-active;
  }
}

.button-overlay {
  background-color: @color-small-button;
  border-radius: 4*@unit;
  color: @color-white;
  font-weight: 600;
  height: auto;
  line-height: 1.4;
  padding: 11.2*@unit 19.6*@unit;

  &:hover {
    background-color: @color-small-button-hover;
  }

  &:active {
    background-color: @color-small-button-active;
  }
}

.fallbackButton {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
  text-decoration: underline;
  background: none;
  border: none;
}
